.options {
  @include ulres;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.options__label {
  display: block;
  cursor: pointer;
}

.options__value {
  position: relative;
  display: block;
  background-color: $bg-light;
  padding: 35px 30px;
  padding-left: 67px;

  font-size: 14px;
  line-height: 1;
  color: $text-main;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 35px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 4px solid #fff;
    border-radius: 50%;
    background-color: #fff;
    transition: $transition;
  }
}

.options__label:hover .options__value {
    background-color: rgba(39, 39, 39, 0.6);

  &::before {
    background-color: transparent;
  }
}

.options__radio:focus ~ .options__value {
  background-color: rgba(39, 39, 39, 0.6);

  &::before {
    background-color: transparent;
  }
}

.options__radio:checked ~ .options__value {
  background-color: $bg-second;
  color: #fff;

  &::before {
    background-color: transparent;
  }
}