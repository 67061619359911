.filter {
  background-color: $bg-second;

  color: #fff;
}

.filter__title {
  margin: 0;
  padding: 25px;
  border-bottom: 4px dashed #fff;

  font-family: $font-second;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
}

.filter__form {
  padding: 35px 25px 60px;
}

.filter__submit {
  width: 100%;
  margin-bottom: 15px;
}

.filter__reset {
  width: 144px;
}
