.check-list {
  @include ulres;
}

.check-list__item {
  &:not(:last-child) {
    margin-bottom: 13px;
  }
}

.check-list__label {
  display: block;
  cursor: pointer;
}

.check-list__desc {
  position: relative;
  display: block;
  padding-left: 32px;
  padding-top: 3px;
  padding-bottom: 3px;

  font-size: 16px;
  line-height: 1;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background-color: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 4px;
    width: 16px;
    height: 13px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8l5 5L18 2' stroke='%23000' stroke-width='3'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    transition: $transition;
  }

  span {
    color: #737373;
  }
}

.check-list__label:hover .check-list__desc::after,
.check-list__label:focus .check-list__desc::after {
  opacity: 0.3;
}

.check-list__check:checked ~ .check-list__desc::after {
  opacity: 1;
}