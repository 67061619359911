.button {
  @include inpres;

  display: inline-block;
  padding: 25px 15px;
  transition: $transition;

  vertical-align: middle;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--primery {
    background-color: $color-primery;
    border: 1px solid $color-primery;

    font-family: $font-second;
    color: $text-main;
    font-size: 13px;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: transparent;
      color: #fff;
    }
  }

  &--second {
    background-color: transparent;
    border: 1px solid #fff;
    padding: 15px;

    color: #fff;
    font-size: 14px;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: #fff;
      color: $text-main;
    }
  }
}

.button-del {
  @include inpres;

  width: 20px;
  height: 20px;
}
