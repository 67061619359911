.header {
  background-color: $bg-second;
}

.header__wrapper {
  display: grid;
  grid-template-columns: 380px 1fr  300px 40px;
  grid-column-gap: 40px;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 23px;
  padding-bottom: 23px;
}

.header__info {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #fff;
  text-transform: uppercase;
}

.header__logo {
  display: block;
  width: 190px;
  height: 33px;
  margin: 0 auto;
}

.header__tel {
  margin-left: auto;

  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.header__cart {
  position: relative;
  height: 27px;

  color: #fff;
}

.header__count {
  position: absolute;
  top: -5px;
  right: -3px;
  background-color: $color-primery;
  padding: 3px;

  font-family: $font-second;
  font-size: 7px;
  line-height: 1;
  color: $text-main;
}