.social {
  @include ulres;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.social__item + .social__item {
  margin-left: 30px;
}

.social__link {
  transition: $transition;
  color: $color-primery;
  
  &:hover,
  &:focus {
    opacity: 0.5;
  }
}