.tabs {
  @include ulres;

  display: flex;
  align-items: center;
  border-bottom: 1px solid #E7E7E7;
}

.tabs__item {
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.tabs__link {
  position: relative;
  display: block;
  padding: 15px 25px;

  font-size: 18px;
  color: $text-main;

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background-color: transparent;
    transition: $transition;
  }

  &:hover::after,
  &:focus::after {
    bottom: -1px;
    background-color: $bg-second;
  }

  &--current {
    font-weight: 700;

    &::after {
      bottom: -1px;
      background-color: $bg-second;
    }
  }
}