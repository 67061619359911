.footer {
  background-color: $bg-second;
}

.footer__wrapper {
  display: grid;
  grid-template-columns: 550px 423px;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer__link {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  border-bottom: 2px dashed transparent;
  transition: $transition;

  &:hover,
  &:focus {
    border-color: $color-primery;
  }

  &--medium {
    font-weight: 500;
  }
}

.footer__links {
  @include ulres;

  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px 30px;
}

.footer__desc {
  margin: 30px 0 37px; 

  font-size: 14px;
  line-height: 24px;
  color: $text-second;
}

.footer__social {
  margin-left: auto;
  align-self: flex-start;
}

.footer__copyright {
  grid-row: 3/4;
  align-self: flex-end;

  font-family: $font-second;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}

.footer__data {
  @include ulres;

  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  justify-content: space-between;
  grid-column: 2/3;
  grid-row: 3/4;
}