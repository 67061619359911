.pagination {
  @include ulres;

  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__item {
  &:first-child {
    margin-right: 25px;
  }

  &:last-child {
    margin-left: 25px;
  }
}

.pagination__link {
  display: block;
  padding: 13px 3px;
  height: 40px;
  min-width: 40px;

  font-size: 16px;
  line-height: 1;
  color: $text-main;
  text-align: center;
  transition: $transition;

  &[href]:not(:disabled):hover,
  &[href]:not(:disabled):focus {
    opacity: 0.6;
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &--current {
    font-weight: 700;
  }

  &--arrow {
    border: 1px solid #E2E2E2;
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}